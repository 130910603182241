































import { ResponsiveMixin } from "@/mixins/Responsive";
import Component, { mixins } from "vue-class-component";
import { Prop, VModel } from "vue-property-decorator";

@Component({
  name: "menu-or-bottom-sheet"
})
export default class MenuOrBottomSheet extends mixins(ResponsiveMixin) {
  @VModel({ type: Boolean, default: false })
  active!: boolean;

  @Prop({ required: false })
  event!: MouseEvent;

  @Prop({ required: false, default: 300 })
  width!: number;

  @Prop({ required: false, default: null })
  header!: string | null;

  get positions(): null | { x: number; y: number } {
    if (this.event) {
      if (this.event.target) {
        const boundingRect = (
          this.event.target as HTMLElement
        ).getBoundingClientRect();
        const res = {
          x: boundingRect.x + boundingRect.width,
          y: boundingRect.y
        };
        if (boundingRect.y < innerHeight / 2) {
          res.y = boundingRect.y + boundingRect.height;
        }
        return res;
      }
      return {
        x: this.event.clientX,
        y: this.event.clientY
      };
    }
    return null;
  }

  get topOrBottom(): "top" | "bottom" | null {
    if (this.positions) {
      if (this.positions.y > window.innerHeight / 2) {
        return "top";
      } else {
        return "bottom";
      }
    }
    return null;
  }
}

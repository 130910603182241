
















































































































































































































import AppDialog from "@/components/Navigation/AppDialog.vue";
import StripeCard from "@/components/Payments/StripeCard.vue";
import StripeBankCard from "@/components/Payments/StripeBankCard.vue";
import { WatchLoading } from "@/decorators/Loading";
import { CaptureEvent, LoadingMixin } from "@/mixins/Helpers";
import Component, { mixins } from "vue-class-component";
import LottieAnimation from "lottie-vuejs/src/LottieAnimation.vue";
import MenuOrBottomSheet from "@/components/Navigation/MenuOrBottomSheet.vue";
import DeleteIcon from "@/assets/icons/saxcons/trash-linear.svg";
import TickIcon from "@/assets/icons/saxcons/tick-circle-linear.svg";
import {
  StripeBankPaymentMethod,
  StripeCardPaymentMethod
} from "@/types/Payment";
import { UserPaymentMethodsMixin } from "@/components/Payments/PaymentMethodsMixin";

@Component({
  name: "user-payment-methods",
  components: {
    TickIcon,
    MenuOrBottomSheet,
    StripeBankCard,
    AppDialog,
    StripeCard,
    LottieAnimation,
    DeleteIcon
  }
})
export default class UserPaymentMethods extends mixins(
  LoadingMixin,
  CaptureEvent,
  UserPaymentMethodsMixin
) {
  selectedPaymentMethod:
    | StripeCardPaymentMethod
    | StripeBankPaymentMethod
    | null = null;

  get noStripeInfo(): boolean {
    return !(
      this.currentUser?.stripeCustomerId &&
      this.$store.getters.merchantInfo?.merchantId
    );
  }

  get merchantWarning(): string | null {
    if (this.noStripeInfo) {
      if (this.$store.getters.featureFlags["coach-merchants"]) {
        return "Your coach hasn't setup their merchant account yet";
      } else {
        return "Your club hasn't setup their merchant account yet";
      }
    }
    return null;
  }

  constructor() {
    super();
    this.isLoading = true;
  }

  openMenu(
    event: MouseEvent & { target: HTMLElement },
    methodInfo: StripeCardPaymentMethod | StripeBankPaymentMethod
  ) {
    this.selectedPaymentMethod = methodInfo;
    this.captureEvent(event);
  }

  async setAsDefault() {
    this.isLoading = true;
    if (this.$stripeClient) {
      await this.$stripeClient.patch(
        `/paymentMethods/${this.selectedPaymentMethod?.id}/default`
      );
      await this.stripeClientLoaded();
    }
  }

  async deleteSelectedCard() {
    // delete the payment method
    if (this.$stripeClient) {
      await this.$stripeClient.delete(
        `paymentMethods/${this.selectedPaymentMethod?.id}`
      );
      await this.stripeClientLoaded();
    }
  }

  @WatchLoading()
  async stripeClientLoaded() {
    await this.fetchMethods();
  }
}
